.section-wrapper.row {
  justify-content: space-between;
  margin-top: 3rem;
}

.about-section-image {
  width: 48%;
}
.about-section img {
  width: 100%;
  height: 100%;
}
.about-section-content {
  width: 48%;
}
.heading-tag {
  display: inline-block;
  margin-bottom: 2rem;
}
.heading-tag span {
  font-size: 2.8rem;
  font-weight: 400;
  color: #ff6500;
}

.section-heading p {
  font-size: 2.2rem;
}
.pointers {
  margin-top: 3.5rem;
}
.pointers ul {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.pointers li {
  position: relative;
  padding-left: 4rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.pointers li::before {
  content: "";
  background: url(../../assets/li-marker.svg);
  position: absolute;
  top: 10px;
  left: 0;
  height: 12px;
  width: 12px;
  background-repeat: no-repeat;
}

.pointers h3 {
  font-size: 2.2rem;
}
.pointers p {
  font-size: 1.8rem;
}
@media (max-width: 768px) {
  .section-heading {
    text-align: left !important;
    width: 100%;
  }
  .section-wrapper.row {
    flex-direction: column-reverse;
    gap: 3rem;
  }
  .about-section-image {
    width: 100%;
  }
  .about-section-content {
    width: 100%;
  }
}
