/* Banner  */
.banner-section {
  position: relative;
  z-index: 1;
  background: linear-gradient(to bottom, #fff7f3 0%, #fff7f3 85%, #ffffff 90%);
}
.banner-content-wrapper {
  padding-top: 20rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  z-index: 1;
  margin-bottom: 10rem;
}

.banner-section h1 {
  transform: translateY(50px);
  opacity: 0;
  transition: 0.6s;
}
h1 span {
  font: inherit;
  color: #f54c00;
}
.banner-content-wrapper p {
  color: #7b7583;
  font-size: 2.2rem;
  width: 60%;
}
.banner-content-wrapper > p {
  transform: translateY(50px);
  transition: 0.6s;
  opacity: 0;
}
.banner-button-wrapper {
  margin-top: 5rem;
  transform: translateY(50px);
  transition: 0.6s;
  opacity: 0;
}
.banner-button {
  background: #ff6500;
  border: none;
  border-radius: 50px;
  display: inline-flex;
  gap: 1rem;
  align-items: center;
  padding: 2rem 4rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: all 0.4s ease-in-out;
  z-index: 10;
  cursor: pointer;
  transform: scale(1);
}
.banner-button:hover {
  animation: pulse 2s infinite;
  transform: scale(1.03);
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 #ff6500;
    box-shadow: 0 0 0 0 #ff6500;
  }
  70% {
    -moz-box-shadow: 0 0 0 8px #f7904ca3;
    box-shadow: 0 0 0 7px #f7904ca3;
  }
  100% {
    -moz-box-shadow: 0 0 0 0 #f7904ca3;
    box-shadow: 0 0 0 0 #f7904ca3;
  }
}
.banner-button p {
  font-size: 1.7rem;
  font-weight: 700;
  margin: 0;
  width: 100% !important;
  color: #fff;
}
.banner-cards-outer {
  overflow: hidden;
}
.reveal-animation .banner-cards-wrapper {
  transition-delay: 0.6s;
  transform: translateY(0);
}
.banner-cards-wrapper {
  background: #6c371d;
  justify-content: space-between;
  padding: 5rem 3rem;
  border-radius: 22px;
  gap: 3rem;
  display: flex;
  transition: 0.6s cubic-bezier(0.22, 1, 0.36, 1);
  transform: translateY(calc(-100% + 4px));
}
.banner-cards-inner {
  overflow: hidden;
  transition: 0.6s cubic-bezier(0.22, 1, 0.36, 1);
  transform: translateX(calc(-100% - 2px));
}
.banner-cards-inner.slide-animation {
  transform: translateX(0);
  transition-delay: 0.2s;
}
.banner-card {
  display: flex;
  gap: 2rem;
  position: relative;
  z-index: 2;
  width: 35%;
}
.banner-card-icon {
  flex-shrink: 0;
}
.banner-card img {
  width: 45px;
}
.banner-card-text {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.banner-card h2 {
  font-size: 2.6rem;
  margin: 0;
  color: #ffffff;
  width: 80%;
}

.banner-card p {
  color: #ffffff;
}
/* Animate */
.animate h1 {
  transform: translateY(0px);
  opacity: 1;
}
.animate .banner-content-wrapper > p {
  transform: translateY(0px);
  opacity: 1;
  transition-delay: 0.3s;
}
.animate .banner-button-wrapper {
  transform: translateY(0px);
  opacity: 1;
  transition-delay: 0.6s;
}
.animate .right-side {
  transform: scale(1);
}
@media (max-width: 768px) {
  .banner-content-wrapper {
    text-align: left;
    align-items: flex-start;
  }
  .banner-content-wrapper p {
    width: 95%;
  }
  .banner-cards-wrapper {
    flex-direction: column;
    gap: 3rem;
  }
  .banner-card {
    width: 100%;
  }
  .banner-section br {
    display: none;
  }
}
@media (max-width: 510px) {
  .tab-links-wrapper {
    flex-direction: column;
    align-items: flex-start;
  }
  .tab-links-wrapper div {
    width: 100%;
    text-align: center;
  }
}
@media (max-width: 495px) {
  .banner-section h1 {
    font-size: 4.2rem;
  }
  .banner-content-wrapper p {
    font-size: 1.8rem;
  }
}
