.second-banner-section {
  background: #feeaec;
  margin-bottom: 0 !important;
  padding: 10rem 0;
}

.second-banner-section-heading {
  text-align: center;
}
.second-banner-section-heading h2 {
  font-size: 4.2rem;
}
.second-banner-section-heading p {
  font-size: 2.1rem;
  color: #000;
}
.second-banner-button-wrapper {
  margin-top: 5rem;
  transition: 0.6s;
}
.second-banner-button {
  background: #ff6500;

  border: none;
  border-radius: 50px;
  display: inline-flex;
  gap: 1rem;
  align-items: center;
  padding: 2rem 4rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: all 0.4s ease-in-out;
  z-index: 10;
  cursor: pointer;
  transform: scale(1);
}
.second-banner-button:hover {
  animation: pulse 2s infinite;
  transform: scale(1.03);
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 #ff6500;
    box-shadow: 0 0 0 0 #ff6500;
  }
  70% {
    -moz-box-shadow: 0 0 0 8px #f7904ca3;
    box-shadow: 0 0 0 7px #f7904ca3;
  }
  100% {
    -moz-box-shadow: 0 0 0 0 #f7904ca3;
    box-shadow: 0 0 0 0 #f7904ca3;
  }
}
.second-banner-button p {
  font-size: 1.8rem;
  font-weight: 700;
  margin: 0;
  color: #fff;
  width: 100% !important;
}

@media (max-width: 768px) {
  .second-banner-section-heading {
    text-align: left;
  }
}
