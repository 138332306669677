.solution-section {
  background: #fff3e8;
  padding: 10rem 0;
}
.section-heading {
  text-align: center;
}
.first-tab-switch-wrapper {
  background: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 22px;
  padding: 2rem;
  margin-top: 5rem;
  box-shadow: 0px 4px 16px 0px #ff804733;
}
.first-tab-switch-body > div {
  display: none;
  animation: fadeEffect 1s;
}
.first-tab-switch-body > .active {
  display: block;
}
.tab-links-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0 3rem;
  border-bottom: 1px solid #b6b6b6;
  margin-bottom: 3rem;
  gap: 2rem;
  flex-wrap: wrap;
}
.tab-links-wrapper div {
  background: #f4f4f4;
  box-shadow: 5px 5px 0px 0px #6c371d;
  transform: translate(-3px, -5px);
  border-radius: 65px;
  padding: 1.2rem 2.9rem;
  cursor: pointer;
  transition: all 0.3s ease;
}
.tab-links-wrapper div:hover {
  background: #e3e3e3;
}
.tab-links-wrapper div.active {
  background: #6c371d;
  box-shadow: none;
  transform: translate(0);
}
.tab-links-wrapper p {
  margin: 0;
  color: #00000085;
  font-size: 2.2rem;
}

.tab-links-wrapper div.active p {
  color: #ffffff;
}

@keyframes fadeEffect {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Tab Switch Body */
.first-tab-switch-content {
  justify-content: space-between;
  align-items: center;
}
.tab-body-left {
  width: 48%;
  border-radius: 22px;
  display: flex;
}
.tab-body-left h3 {
  font-size: 2.8rem;
  font-weight: 600;
  color: #2e6823;
  width: 95%;
}
.tab-body-right {
  width: 48%;
  padding-right: 1rem;
}

.tab-body-right h3 {
  font-size: 3rem;
  margin-bottom: 1rem;
  font-weight: 600;
}
.tab-body-right p {
  font-size: 2rem;
  color: #2c2c2c;
}

.tab-body-right ul {
  margin-top: 3.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.tab-body-right li {
  position: relative;
  padding-left: 3rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.tab-body-right li::before {
  content: "";
  background: url(../../assets/li-marker.svg);
  position: absolute;
  top: 10px;
  left: 0;
  height: 12px;
  width: 12px;
  background-repeat: no-repeat;
}

@media (max-width: 768px) {
  .first-tab-switch-content {
    flex-direction: column;
    gap: 3rem;
  }
  .first-tab-switch-content div {
    width: 100%;
  }
}
