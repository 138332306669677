.features-cards-wrapper {
  margin-top: 7rem;
  row-gap: 3rem;
  justify-content: center;
}
.feature-cards {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: 100%;
}
.features-card {
  padding: 2.5rem;
  border-radius: 32px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: 100%;
}
.feature-heading {
  display: flex;
  gap: 1.5rem;
  align-items: center;
}
.feature-heading img {
  width: 50px;
  height: 50px;
}
.features-card h3 {
  font-size: 2rem;
}
.features-card p {
  font-size: 1.8rem;
  color: #000000;
}

.first-card {
  background: #ffe9c8;
}
.second-card {
  background: #daf9d4;
}
.third-card {
  background: #c9f0ff;
}
.fourth-card {
  background: #ff835f;
  height: 100%;
  padding: 2.5rem 0 0 2.5rem;
}
.fourth-card .feature-heading,
.fourth-card .feature-description {
  padding-right: 2.5rem;
}
.fourth-card h3 {
  color: #fff;
}
.fourth-card p {
  color: #f4f4f4;
}

.fifth-card {
  background: #ede6de;
}
.sixth-card {
  background: #ffd2e0;
}
.seventh-card {
  background: #f4f6ff;
}
@media (max-width: 768px) {
  .support-image img {
    width: 60%;
    display: block;
    margin: 0 auto;
  }
}
