.use-cases-section {
  background: #f9f9f9;
  padding: 10rem 0;
  margin-bottom: 0 !important;
}
.section-heading {
  text-align: center;
}
.section-heading p {
  font-size: 1.8rem;
}

.second-tab-switch-wrapper {
  margin-top: 10rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.second-tab-switch-content {
  display: none;
  animation: fadeEffect 1s;
}
.second-tab-switch-content.active {
  display: block;
}
.tab-links {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 40%;
}
.tab-links > div {
  background: #fbfcff;
  border-top: 1px solid #e1e1fd;
  padding: 2rem;
  cursor: pointer;
}
.tab-links > div:last-child {
  border-bottom-left-radius: 22px;
}
.tab-links > div.active {
  background-color: #ffffff;
  padding-left: calc(2rem - 5px);
  border-left: 5px solid #ff6500;
}
.tab-1 {
  border-radius: 22px 0 0 0;
}
.tab-3 {
  border-radius: 0 0 0 22px;
}
.tab-link-heading {
  display: flex;
  gap: 1rem;
  margin-bottom: 1.3rem;
}

.tab-link-heading img:first-child {
  width: 40px;
}
.tab-link-heading h3 {
  font-size: 1.8rem;
  color: #262626;
}
.tab-link-heading img:last-child {
  margin-left: auto;
}
.tab-links p {
  font-size: 1.4rem;
  color: #5e6575;
}
.second-tab-switch-body {
  width: 55%;
}
@keyframes fadeEffect {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .second-tab-switch-wrapper {
    flex-direction: column;
    gap: 3rem;
  }
  .tab-links {
    width: 100%;
    flex-direction: column;
  }
  .second-tab-switch-body {
    width: 100%;
  }
}
