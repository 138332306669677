.first-accordion .section-heading {
  text-align: center;
}

.accordion {
  margin: 0 auto;
}

.accordion .accordion-item {
  border-bottom: 1px solid #0000004d;
  padding: 10px 8px 0 0;
}
.accordion button {
  text-align: left;
  width: 100%;
  padding: 1.6rem 0;
  display: flex;
  justify-content: space-between;
  border: none;
  background: none;
  outline: none;
  align-items: center;
  position: relative;
}
.accordion button:hover,
.accordion button:focus {
  cursor: pointer;
}
.accordion button:hover::after,
.accordion button:focus::after {
  cursor: pointer;
}
.accordion button .accordion-title {
  font: 500 2rem/5rem Inter;
  color: #3a3a3c;
  width: 90%;
  padding-right: 3rem;
  margin: 0;
}
.accordion .accordion-content p {
  color: #667085;
  margin-bottom: 20px;
  width: 95%;
}

.accordion button[aria-expanded="true"] .icon::after {
  border: none;
}
.accordion button[aria-expanded="true"] + .accordion-content {
  opacity: 1;
  max-height: 36em;
  transition: all 200ms linear;
  will-change: opacity, max-height;
}
.accordion .accordion-content {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: opacity 200ms linear, max-height 200ms linear;
  will-change: opacity, max-height;
  padding-right: 3rem;
}
.accordion-hide {
  display: none;
}
.accordion button .icon {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  border: 1px solid #334960;
  position: relative;
  transition: all 200ms linear;
  margin: 0;
}
.icon::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 50%;
  width: 2px;
  border: 1px solid #334960;
}
.icon::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 2px;
  width: 50%;
  border: 1px solid #334960;
}
@media (max-width: 768px) {
  .accordion {
    width: 100%;
  }
  .accordion button .accordion-title {
    display: block;
    line-height: 21px;
  }
  .accordion .accordion-content p {
    display: block;
  }
}
@media (max-width: 576px) {
  .accordion button .icon {
    width: 3rem;
    height: 3rem;
  }
}
