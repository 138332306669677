.roi-section {
  background: #fffce2;
  padding: 10rem 0;
  margin-top: 0 !important;
}

.roi-content-wrapper {
  display: flex;
  align-items: center;
  gap: 9rem;
  margin: 6rem 0 0;
}
.roi-stats-cards {
  row-gap: 6rem;
  width: 50%;
  justify-content: space-between;
}
.roi-card {
  width: 45%;
  text-align: center;
}
.roi-card h3 {
  font-size: 5rem;
  font-weight: 600;
  color: #ff8047;
}
.roi-card h4 {
  font-size: 2rem;
  font-weight: 600;
  color: #101828;
  margin-bottom: 8px;
}
.roi-card p {
  color: #667085;
}
.roi-card img {
  transform: scale(0);
  transition: all 1s ease;
  transform-origin: left;
  width: 100%;
}
.section-animation .roi-card img {
  transform: scale(1);
}
@media (max-width: 1440px) {
  .roi-stats-cards {
    width: 63%;
  }
}
@media (max-width: 1024px) {
  .roi-stats-cards {
    width: 70%;
  }
}
@media (max-width: 768px) {
  .roi-content-wrapper {
    flex-direction: column-reverse;
  }
  .roi-stats-cards {
    width: 100%;
  }
  .roi-card {
    text-align: left;
  }
}
@media (max-width: 418px) {
  .roi-stats-cards {
    flex-direction: column;
    row-gap: 3rem;
  }
  .roi-card {
    width: 100%;
  }
}
